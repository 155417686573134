import styled from 'styled-components';

import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { MaxWidthLayout } from 'components/common';
import { theme } from 'styles/theme';

import { Container } from '../../components/container';

export const Wrapper = styled.article`
  padding-bottom: ${sizeUnits[24]};
`;

export const Converter = styled.div`
  display: none;
  ${({ theme }) => theme.mq.tablet} {
    margin: ${sizeUnits[24]} 0;
    display: flex;
    align-items: center;

    a {
      font-weight: 500;
      margin-right: ${sizeUnits[32]};
    }
  }
`;

export const ScheduleContainer = styled(Container)`
  ${MaxWidthLayout()}

  background-color: ${theme.colors.beige.light};
`;

export const ChannelGrid = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  max-width: 100%;
  padding: 0 ${sizeUnits[16]};
  ${({ theme }) => theme.mq.tablet} {
    padding: 0;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
  padding-bottom: 50px;
`;

export const ChannelScrollAnchor = styled.span`
  position: absolute;
  top: -180px;
`;

export const Channel = styled.div`
  position: relative;
  background: transparent;
  padding-top: 48px;
  margin: -48px 0 ${sizeUnits[16]};
  width: 380px;
  padding-bottom: 38px;
  ${({ theme }) => theme.mq.tablet} {
    padding-top: 0;
    margin-top: 0;
    margin-right: ${sizeUnits[16]};
  }
`;
export const ChannelHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 48px;
  background: ${({ theme }) => theme.colors.beige.light};
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  height: 48px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 80%;
    max-height: 48px;
    display: block;
    user-select: none;
  }
`;
