import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { StaticPageId } from '@news/tracking';

import { Canonical } from 'components/Canonical';
import { usePageContext } from 'contexts/PageContext';
import { useSifoTracking } from 'hooks/useSifoTracking';
import { getCacheControl } from 'lib/helpers';

import { TV } from '../views/tv';

const TvPage: NextPage = () => {
  const router = useRouter();
  const { updatePageInfo } = usePageContext();
  const date = typeof router.query.date === 'string' ? router.query.date : undefined;
  useSifoTracking();

  // will return root path .... tabla
  const path = router.asPath.split('/').filter(Boolean);

  useEffect(() => {
    updatePageInfo({
      pageData: {
        page_path: router.asPath,
        page_id: StaticPageId.Schedule,
        page_type: 'other',
        page_title: 'Tablå',
        page_tags: null,
        page_feed: null,
      },
    });
  }, [updatePageInfo, router.asPath]);

  return (
    <>
      <Canonical path={path.length > 0 ? `/${path[0]}` : router.asPath} />
      <TV date={date} />
    </>
  );
};

TvPage.getInitialProps = (context) => {
  context.res?.setHeader('Cache-Control', getCacheControl());
  return {
    props: {},
  };
};

export default TvPage;
