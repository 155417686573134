import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import type { RefObject } from 'react';
import { createRef, useRef } from 'react';
import styled from 'styled-components';

import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import { useScheduleQuery } from '@news/gql';
import { CHANNEL_LOGOS, getScheduleDates, isNotNullish } from '@news/lib';
import { appendUtmTags } from '@news/tracking';

import { CustomHeader } from 'components/CustomHeader';
import { Typography } from 'components/Typography';
import { SecondaryButton } from 'components/buttons';
import { ScreenReader, SkipLinkTarget } from 'components/common';
import { Loader } from 'components/loading/style';
import { Redirect } from 'components/redirect';
import { notifyBugsnag } from 'lib/bugsnagClient';
import { hasInvalidScheduleData } from 'lib/helpers';
import { ImageProxy } from 'lib/imageProxy';

import {
  Channel,
  ChannelGrid,
  ChannelScrollAnchor,
  Converter,
  LoaderContainer,
  ScheduleContainer,
  Wrapper,
} from './styles';

const AdPlacement = dynamic(() => import('components/ad-placements/AdPlacement').then((module) => module.AdPlacement), {
  ssr: false,
});

const ChooseScheduleDayMenu = dynamic(() =>
  import('./components/chooseScheduleDay').then((module) => module.ChooseScheduleDayMenu)
);
const ChannelsMenuMobile = dynamic(() =>
  import('./components/channelMenu').then((module) => module.ChannelsMenuMobile)
);

const BroadcastList = dynamic(() => import('./components/broadcastList').then((module) => module.BroadcastList));

const CustomError = dynamic(() => import('components/error').then((module) => module.CustomError));

export interface Day {
  date: string;
  text: string;
}

interface ITVProps {
  date?: string;
}

export function TV({ date }: ITVProps) {
  const router = useRouter();
  const now = Date.now();
  const anchorElements = useRef<Array<RefObject<HTMLElement>>>([]);
  const days: Day[] = getScheduleDates(now);
  const today = days[1] as Day;
  const dayToFetch: string = date ?? today.date;
  const checkWhatsLive: boolean = dayToFetch === today.date;
  const humanDayToFetch: Day | undefined = days.find((day) => day.date === dayToFetch);

  const { called, data, error, loading } = useScheduleQuery({
    variables: {
      date: dayToFetch,
    },
    fetchPolicy: 'cache-first',
  });

  const schedule = (data?.schedule ?? []).filter(isNotNullish);
  const slug = `/tabla/${dayToFetch}`;

  if (error) {
    notifyBugsnag({ error, slug, query: 'useScheduleQuery' });
    return <CustomError statusCode={500} />;
  }

  if (hasInvalidScheduleData({ todaysDate: today.date, dayToFetch, schedule }) && !loading) {
    return <Redirect to="/tabla" statusCode={301} />;
  }

  const channels = schedule
    ? schedule.map((channel) => {
        const logo = CHANNEL_LOGOS[channel.metadataChannelId];
        return {
          id: channel.metadataChannelId,
          name: channel.displayName,
          logo: logo || '',
        };
      })
    : [];

  const arrLength = schedule?.length || 0;
  if (anchorElements.current.length !== arrLength) {
    anchorElements.current = Array(arrLength)
      .fill(null)
      .map((_, i) => anchorElements.current[i] || createRef());
  }

  const channelText = channels.map((channel) => channel.name).join(', ');
  const dayToday = days.find((day) => day.date === dayToFetch);
  const titleDate = date ? (dayToday?.date ?? date) : 'idag';
  const heading = 'Tablå';

  return (
    <>
      <CustomHeader
        description={`Tablå och TV-guide för ${dayToFetch}: ${channelText}`}
        title={`Tablå för ${titleDate}`}
      />

      <ChooseScheduleDayMenu days={days} dayToFetch={dayToFetch} />
      <ChannelsMenuMobile channels={channels} />

      <ScheduleContainer id="main-content">
        <SkipLinkTarget />
        <Wrapper as="div">
          <ScreenReader as="h1">{heading}</ScreenReader>
          <Converter>
            <CTAButton
              as="a"
              href={appendUtmTags('https://www.tv4play.se/kanaler', 'Link')}
              title="TV4 Play"
              target="_blank"
            >
              <Typography variant="CTA">Se på TV4 Play</Typography>
            </CTAButton>
          </Converter>
          <AdPlacement mobile="mobile1" desktop="desktop1" />
          <ChannelGrid>
            {loading && (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            )}
            {!schedule && called && router.asPath !== '/tabla' && <Redirect to="/tabla" statusCode={302} />}
            {!loading &&
              schedule &&
              schedule.map((channel, index) => {
                const logo = CHANNEL_LOGOS[channel.metadataChannelId] ?? '';
                const logotype = ImageProxy.url({ url: logo, width: 200 });
                const broadcasts = channel?.broadcasts.filter(isNotNullish);
                return (
                  <Channel key={channel.metadataChannelId}>
                    <ChannelScrollAnchor ref={anchorElements.current[index]} id={channel.metadataChannelId} />
                    {broadcasts && broadcasts.length > 0 && (
                      <BroadcastList
                        scrollRef={anchorElements.current[index]}
                        broadcasts={broadcasts}
                        channelId={channel.metadataChannelId}
                        channelName={(channel.displayName || channel.name) ?? ''}
                        checkWhatsLive={checkWhatsLive}
                        day={humanDayToFetch}
                        logotype={logotype}
                      />
                    )}
                  </Channel>
                );
              })}
          </ChannelGrid>
          <AdPlacement mobile="mobile1" desktop="desktop1" />
        </Wrapper>
      </ScheduleContainer>
    </>
  );
}

const CTAButton = styled(SecondaryButton)`
  padding: ${sizeUnits[12]} ${sizeUnits[24]};
`;
